<template>
  <transition name="fade" v-if="loading">
    <loading-component></loading-component>
  </transition>

  <transition v-if="!loading" name="fade">
      <div id="wrapper">
        <sidebar :page="'Home'" :sidebar_data=sidebar_data></sidebar>

        <div class="backdrop" @click="close()"></div>

        <nav :class="'navbar bg-transparent p-0 pb-2'">
            <div class="container-fluid">
                <div class="navbar-brand bars me-2 mt-2" @click="this.$router.push({name: 'Home'})">
                  <e class="ph-arrow-left" />
                </div>

                <img :src="require('../assets/img/logo-orange.png')" alt="" style="height: 75px;">       

                <notifications :user="user" :color_notifications_icons="'orange'"/>

                <div class="divider mt-4 d-none"></div>
            </div>
        </nav>
        
        <floating-nav :page="'MyDeliveries'"></floating-nav>

        <div class="body mb-4">
          <div class="container" style="padding: 1rem 2rem 0 2rem">          

            <div class="row" v-if="!data_loading && delivery_data.length > 0">
              <div class="col-md-12 col-sm-12 mt-2">
                  <div class="d-flex mb-2">
                    <h5 class="text-orange fw-bold">Recent Orders</h5>
                  </div>
              </div>

              
              <div class="col-md-6 col-sm-12 col-lg-6" v-for="({cart, price, delivery_to, status, created_at, id}, index) in delivery_data" :key="index">
                <div class="card mb-3" style="cursor: pointer; box-shadow: 0 4px 6px #eee; border-color: #fafafa" @click="this.$router.push({name: 'DeliveryStatus', params: {delivery_id: id}})">
                  <div class="card-body p-3">
                    <div class="row">
                      <h6 class='text-dark fw-bold mt-2 mb-1 text-capitalize'>Order {{ id }} </h6>
                      <small class="text-muted mb-3 fst-italic">Created at {{ created_at }}</small>
                    </div>

                    <div class="row">
                      <div class="col">
                        <p> <font-awesome-icon class="text-dark me-2" :icon="['fa', 'circle-notch']"></font-awesome-icon> Delivery to {{ delivery_to }} </p>
                        <p> <font-awesome-icon class="text-dark me-2" :icon="['fa', 'money-bill-wave']"></font-awesome-icon> KSH. {{ price }} </p>
                      </div>
                    </div>

                    <div class="row">
                      <p class="mb-3">
                        <span class="fw-bold bg-dark text-white p-1 rounded-2">
                          Your Purchases
                        </span>
                      </p>
                    </div>

                    <div class="row d-block">
                      <div class="col" v-for="(stock, index) in cart" :key="index">
                        <p> <font-awesome-icon class="text-dark me-2" :icon="['fa', 'prescription']"></font-awesome-icon> {{ stock.name }} </p>
                      </div>
                    </div>                          

                    <div class="d-flex">
                      <div class="d-block ms-auto">
                        <p class="mb-0 mt-0 fw-bold text-success" v-if="status">
                          <font-awesome-icon class="me-2" :icon="['fas', 'check-circle']" style="margin-top: 3px"></font-awesome-icon> 
                          <span class="text-uppercase">{{ status }}</span>
                        </p>

                        <!-- <p class="mb-0 mt-0 fw-bold" v-if="status" />
                          <font-awesome-icon :icon="['fa', 'angle-down']"></font-awesome-icon> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="load-more" class="col-md-12 col-sm-12 p-4 pt-1">
                <div class="card bg-transparent bg-gradient border-0">
                  <div class="card-body"></div>
                </div>
              </div>          
            </div>
            
            <no-data class="mt-4" v-if="!data_loading && !delivery_data.length" :message="'No Orders'"></no-data>

            <loading-component v-if="data_loading"></loading-component>
          </div>
        </div>
      </div>
  </transition>
</template>

<style lang="scss" scoped>
  @import '../assets/scss/layout/_navbar.scss';
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .nav-bottom {
    border-top-left-radius: 80%; 
    padding: 0rem 2rem 0.5rem 2rem;
    border-top-right-radius: 80%;
    z-index: 1;

    .nav-item {
      cursor: pointer;
    }
  }

  .form-control {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    line-height: 2.1;
  }
  
  .input-group-text {
    background: transparent;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }  
</style>

<script>
  import Sidebar from '../components/AutoSidebar'
  import FloatingNav from '../components/FloatingNav'
  import NoData from '../components/NoData'
  import LoadingComponent from '../components/Loading'
  import Notifications from '../components/Notifications'

  export default {
    name: 'SavedProducts',
    components: {
      Sidebar,
      FloatingNav,
      NoData,
      LoadingComponent,
      Notifications
    },   
    data() {
      return {
        sidebar_data: [
          {title: 'Home', link: '', if_children: false, icon: 'home', modal: false},
          {title: 'My Account', link: 'profile', if_children: false, icon: 'user', modal: false},
        ],
        errors: {},
        screen: 0,
        stock_type: '',
        type: '',
        chosen_stock: null,
        search: null,
        delivery_data: [],
        data_loading: false
      }
    },
    watch: {
      user: function() {
        this.ifNotLoggedIn()
        this.myDeliveries()
      }
    },
    computed: {
      user () {
        return this.$store.state.user
      },
      loading () {
        return this.$store.state.loading
      }       
    },    
    mounted() {
      this.$store.dispatch("getUser")
    },
    methods: {
      routeTo(value) {
        this.$router.push({name: value});
      },
      ifNotLoggedIn() {
        if( !this.user.name) {
          this.$router.push({name: 'Login'});
        } 
      },
      myDeliveries() {
        this.data_loading = true
        this.axios.get('api/user-delivery').then(response => {
          this.data_loading = false
          this.delivery_data = response.data.data
          console.log(response.data.data);
        }).catch(error => {
          this.data_loading = false
          console.log(error.response.data);
        })
      }
    }    
  }
</script>
